.app-nav.navbar-default {
    background-color: #2e2e38;
    border-color: #2e2e38;
}

.app-nav.navbar-default .navbar-nav > li > a {
    color: #9c9696;
}

.app-nav.navbar-default .navbar-nav > li > a:hover,
.app-nav.navbar-default .navbar-nav > li > a:focus {
    color: #fff;
    background-color: transparent;
    border-bottom: 4px solid #ffe600;
}
.app-nav.navbar-default .navbar-nav > li.list-item > .active,
.app-nav.navbar-default .navbar-nav .dropdown > a.active {
    border-bottom: 4px solid #ffe600;
    color: #fff;
}
.app-nav.navbar-default .navbar-nav > li.cart-item > a:hover,
.app-nav.navbar-default .navbar-nav > li.cart-item > a:focus,
/* .app-nav.navbar-default .navbar-nav > li.signin-item > a:hover,
.app-nav.navbar-default .navbar-nav > li.signin-item > a:focus,
.app-nav.navbar-default .navbar-nav > li.signin-item > .active, */
.app-nav.navbar-default .navbar-nav > li.cart-item > .active {
    border-bottom: 0px solid #ffe600;
}
.app-nav.navbar-default .navbar-nav > li.cart-item > a.active  .fa-cart-arrow-down{
    color: #fff;
}
.app-nav .navbar-brand {
    height: auto;
    padding: 13px 22px;
}

.app-nav .navbar-brand .brand-logo{
    width: 113px;
    height: 55px;
}

.app-nav .navbar-collapse .nav{
    padding: 0.669% 22px 0.661% 0
}

.app-nav .navbar-header .navbar-toggle {
  margin: 5.9% 22px;
}

.app-nav.navbar-default .navbar-toggle:hover,
.app-nav.navbar-default .navbar-toggle:focus {
    background-color: #ffe600;
}

.app-nav.navbar-default .navbar-toggle:hover .icon-bar,
.app-nav.navbar-default .navbar-toggle:focus .icon-bar {
    background-color: #2e2e38;
}

.app-nav.navbar-default .navbar-nav > li.cart-item  .fa-cart-arrow-down {
    font-size: 25px;
    color: #9c9696
}
.app-nav.navbar-default .navbar-nav > li.cart-item  .fa-cart-arrow-down:hover {
    color: #fff;
}
.app-nav .dropdown-menu{
    background-color: #47474a;

}
.app-nav .dropdown-menu > li > a{
    color: #b5adad;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    background-color: transparent;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a.active {
    color: #ffff;
    background-color: #2e2e38;
}

.contactus-button {
    text-decoration: none;
    color: white;
    padding: 10px;
    text-transform: uppercase;
    display: inline-block;
    text-shadow: -2px 2px 0 rgba(0, 0, 0, 0.2);
    font-weight: bold;
    padding-right: 50px;
    margin: 10px;
    transition: all 0.1s linear;
    transform: translateZ(0);
    margin-top: 22px;
}
.contactus-button.yellow {
    background: linear-gradient(to bottom, #FFE600, #FFE600);
    color: black;
    text-shadow: -2px 2px 0 rgba(255, 255, 255, 0.3);
    box-shadow: -1px 0px 1px #d9b826, 0px 1px 1px #b1961d, -2px 1px 1px #d9b826, -1px 2px 1px #b1961d, -3px 2px 1px #d9b826, -2px 3px 1px #b1961d, -4px 3px 1px #d9b826, -3px 4px 1px #b1961d, -5px 4px 1px #d9b826, -4px 5px 1px #b1961d, -6px 5px 1px #d9b826, -6px 7px 0 rgba(0, 0, 0, 0.05), -5px 8px 0 rgba(0, 0, 0, 0.05), -3px 9px 0 rgba(0, 0, 0, 0.04), -2px 10px 0 rgba(0, 0, 0, 0.04), -1px 11px 0 rgba(0, 0, 0, 0.03), 0px 12px 0 rgba(0, 0, 0, 0.03), 1px 13px 0 rgba(0, 0, 0, 0.02), 2px 14px 0 rgba(0, 0, 0, 0.02), 3px 15px 0 rgba(0, 0, 0, 0.01), 4px 16px 0 rgba(0, 0, 0, 0.01), 5px 17px 0 rgba(0, 0, 0, 0.01), 6px 18px 0 rgba(0, 0, 0, 0.01), inset 0 4px 5px -2px rgba(255, 255, 255, 0.5), inset 0 1px 0 0 rgba(0, 0, 0, 0.3);
}
.contactus-button.yellow::after, .contactus-button.yellow::before {
    background: black;
}
.contactus-button.yellow::after {
    filter: drop-shadow(-2px 0 0 rgba(255, 255, 255, 0.4));
}
.contactus-button.yellow::before {
    filter: drop-shadow(0 -2px 0 rgba(82, 75, 75, 0.35));
}
.contactus-button.yellow .arrow {
    filter: drop-shadow(-2px 0 0 rgba(255, 255, 255, 0.4));
}
.contactus-button:active {
    box-shadow: none;
    transform: translate3d(-6px, 6px, 0);
}
.contactus-button .arrow {
    filter: drop-shadow(-2px 0 0 rgba(0, 0, 0, 0.2));
}
.contactus-button::after {
    filter: drop-shadow(-2px 0 0 rgba(0, 0, 0, 0.2));
}
.contactus-button::after, .contactus-button::before {
    position: absolute;
    content: " ";
    right: 15px;
    top: 14px;
    width: 6px;
    height: 18px;
    background: white;
    transform: rotate(-45deg);
    display: block;
    z-index: 2;
}
.contactus-button::before {
    height: 14px;
    top: 26px;
    right: 16px;
    z-index: 3;
    transform: rotate(-137deg);
    filter: drop-shadow(0 -2px 0 rgba(0, 0, 0, 0.15));
}