h1, h2, h3, h4, h5 {
  font-weight: 300;
}

h3 {
  font-size: 3.2rem;
  line-height: 40px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  margin-top: 10px;
}

.ant-spin-dot-item {
  background-color: #2e2e38;
}

.ant-spin{
color: #2e2e38;
}

.ant-badge-count {
  min-width: 15px;
  height: 15px;
  padding: 0px 4px;
  color: #2e2e38;
  font-size: 10px;
  line-height: 15px;
  background: #ffe600;
}

.ant-table-thead > tr > th {
  color: #2e2e38;
  background: #ffe600;
}
