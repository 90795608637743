 
.container-sec {
    position:relative;
    width:100%;
    height:500px;
    box-shadow:0px 0px 20px rgba(0, 0, 0, .5);
    background: #ffe600;
    background-size:cover;
    overflow:hidden;
    z-index:4;
    transition:all 1.5s;
}

.sign-in-up .leftSide,
.sign-in-up .rightSide {
    position:absolute;
    width:300px;
    color: #2e2e38;
    text-align:center;
    box-sizing:border-box;
    padding:20px 5px;
    top:0;
    z-index:2;
    transition:all 1.5s;
}

.sign-in-up .leftSide {
    left:-300px;
    transition:all 1.5s;
}

.sign-in-up .rightSide {
    right:0px;
    transition:all 1.5s;
}

label {
    position:absolute;
    top:0;
    font-size:16px;
    font-family:Oxygen;
}

.wrapper {
    position:relative;
    padding:20px 0;
}


.loginContent,
.signupContent {
    position:relative;
    height:100%;
    width:100%;
    transition:all 1.5s;
}

.signupContent {
    margin-left:-800px;
    transition:all 1.5s;
}

.loginBlock,
.signupBlock {
    position:absolute;
    height:100%;
    z-index:3;
}

.loginBlock {
    background:white;
    width:calc(100% - 300px);
    margin-left:0px;
    overflow:hidden;
    transition:all 1.5s;
}

.wrapper  input:not([type="button"]) {
    display:block;
    outline:none;
    border:none;
    border-bottom:1px solid silver;
    font-size:22px;
    font-family:Oxygen;
}

.wrapper  input:not([type="button"]):focus {
    border-bottom:1px solid #2e2e38;
}

.wrapper  input:not([type="button"]):focus + label {
    color:#2e2e38;
}

.wrapper  input:not([type="button"]):valid ~ i[class*="check-square"] {
    opacity:1;
  }

i[class*="check-square"] {
    opacity:0;
    position:absolute;
    right:10px;
    bottom:30px;
    transition:all .5s;
}

.sign-in-btn,
.sign-up-btn {
    color: rgb(228 222 222 / 85%);
    background: #2e2e38c7;
    border-color: #2e2e38;
    width: 100%;
    margin-top: 20px;
    height: 40px;
}

.sign-in-btn:hover,
.sign-up-btn:hover {
    background: #2e2e38;
    color: #fff;
    border-color: #2e2e38;
}

.bar {
    position:absolute;
    width:300px;
    color:#2e2e38;
    text-align:center;
    box-sizing:border-box;
    margin-left:calc(100% - 300px);
    height:100%;
    z-index:1;
}

.mask {
    width:100%;
    height:100%;
    padding:20px 5px;
    box-sizing:border-box;
    transition:all 1.5s;
}

.title {
    font-size:24px;
    font-family:Oxygen;
    margin:10px;
}

.text {
    font-family:Noto Sans;
}
  
.toggle {
    position:relative;
    font-size:20px;
    font-family:Nunito;
    border:3px solid #2e2e38;
    border-radius:50px;
    width:100px;
    margin:300px auto 0px auto;
    padding:3px;
    cursor:pointer;
    overflow:hidden;
    box-sizing:border-box;
}

.dot {
    visibility:hidden;
}

.loginText {
    position:absolute;
    top:-100%;
    left:50%;
    transform:translate(-50%, -50%);
}

.signupText {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
}

.signupBlock {
    top:0;
    left:100%;
    width:calc(100% - 300px);
    background:white;
    overflow:hidden;
    transition:all 1.5s;
}

.loginForm,
.signupForm {
    position:absolute;
    top:20%;
    left:20%;
    /* transform:translate(20%, 20%); */
  }

.signin-min-height{
    min-height: 71vh;
} 