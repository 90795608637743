.products {
	padding: 40px 0;
	background: linear-gradient(to right, #ffe600 0%, #ffe600 100%);
	color: #2e2e38;
}
.products .product-desc {
	margin: 10px 10px 20px;
}
.products h3 {
	color: #2e2e38;
}
.products .section-title h3::after {
	position: absolute;
	content: "";
	background: #2e2e38b8;
	height: 4px;
	width: 60px;
	bottom: 4%;
	margin-left: -30px;
	left: 50%;
}
.products i.fa {
	font-size: 42px;
	width: 120px;
	height: 120px;
	padding: 40px 0;
	transition: transform .2s;
	background: linear-gradient(to right, #b3a739 0%, #ffe600 100%);
	border-radius: 50%;
	color: #2e2e38;
	border: 2px solid #f9e740;
	/* box-shadow: 10px 10px 10px rgba(0,0,0,.05); */
}
.products i.fa:hover{
	cursor: pointer;
	transform: scale(1.1);

}
.products h3 {
	padding: 5px 0;
	color: #2e2e38;
}
.products p {
	color: #2e2e38;
}
.products .product-desc {
	margin-bottom: 40px;
}
.product-tile {
	padding: 0 30px;
}