.scholar-sec-container,
.live-training-wrapper,
.training-certificate-wrapper {
    border-color: transparent;
    box-shadow: 0 4px 4px -2px rgb(0 0 0 / 16%);
    padding: 20px;
    background: #ffff;
}

.scholar-sec-container .scholar-title h3::after,
.live-training-wrapper .live-training-title h3::after,
.training-certificate-wrapper .training-certificate-title h3::after {
    position: absolute;
    content: "";
    background: #ffe600;
    height: 4px;
    width: 60px;
    bottom: 2%;
    left: 1.1%;
}

.live-training-list-sec .ant-card-cover,
.training-certificate-list-sec .ant-card-cover {
    border-bottom: 1px solid #d7d7d7;
    overflow: hidden;
 }

.live-training-list-sec .ant-card-cover img,
.training-certificate-list-sec .ant-card-cover img {
    width: 100%;
    height: 131px;
}

.live-training-list-sec .ant-card-hoverable,
.training-certificate-list-sec .ant-card-hoverable {
    cursor: default;
}

.lang-info{
    font-size: 18px;
    margin-bottom: 10px;
}

.live-training-list-sec .ant-card-body,
.training-certificate-list-sec .ant-card-body {
    padding: 10px 10px 15px 10px;
    background: #f9f9f9;
    text-align: center;
}

.live-training-list-sec .ant-card-cover:hover{
    cursor: pointer;
}


