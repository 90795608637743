.module-sec-wrapper,
.module-list-wrapper,
.module-highlight-wrapper,
.two-modules-other-details-sec-wrapper{
    border-color: transparent;
    box-shadow: 0 4px 4px -2px rgb(0 0 0 / 16%);
    padding: 20px;
    background: #ffff;
}

.module-sec-wrapper .two-modules-title h3::after,
.module-highlight-wrapper .module-highlight-title h3::after,
.module-list-wrapper .module-list-title h3::after {
    position: absolute;
    content: "";
    background: #ffe600;
    height: 4px;
    width: 60px;
    bottom: 2%;
    left: 1.1%;
}

.module-sec-wrapper .two-modules-title h3::after {
    left: 1.5%;
}

.module-list-wrapper .module-list-item .ant-card-cover {
    border-bottom: 1px solid #d7d7d7;
    overflow: hidden;
 }
.module-list-wrapper .module-list-item .ant-card-cover img {
    width: 100%;
    height: 131px;
}
.module-list-wrapper .module-list-item .ant-card-hoverable {
    cursor: default;
}

.lang-info{
    font-size: 11px;
    margin-bottom: 10px;
}
.module-list-wrapper .module-list-item .ant-card-body {
    padding: 10px 10px 15px 10px;
    text-align: center;
    background: #f9f9f9;
}

.module-list-wrapper .module-list-item .ant-btn {
    height: 20px;
    padding: 2px 4px;
    font-size: 9px;
    background: #f1f0f0;
    margin-bottom: 0.5rem;
}

.module-list-wrapper .module-list-item .ant-card-cover:hover{
    cursor: pointer;
}

.module-list-wrapper .module-list-item .ant-btn:hover,
.module-list-wrapper .module-list-item .ant-btn:focus, .ant-btn:active {
    background: #2e2e38;
    color: #fff;
    border-color: #2e2e38;
}

li{
    text-align: justify;
}
