.delete-item{
    font-size: 25px;
}

.cost-table .ant-table-thead > tr > th{
    width: 30%;
}

.cost-table .ant-table-thead > tr :last-child{
    width: 10%;
}

.cart-total-row {
    background: #fff;
    padding: 15px;
    font-size: 16px;
    border-bottom: 1px solid #f0f0f0;
}
.cart-min-height{
    min-height: 76.5vh;
}
.steps-content {
    min-height: 200px;
    margin-top: 16px;
    padding: 10px;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
  }
  
  .steps-action {
    margin-top: 24px;
  }

  .ant-steps-item-icon .ant-steps-icon {
    top: 0px;
    line-height: 1.5;
  }