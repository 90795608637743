.module-item-sec{
    color: #2e2e38;
}

.detail-sec-container,
.add-cart-sec-container,
.course-details {
    border-color: transparent;
    box-shadow: 0 4px 4px -2px rgb(0 0 0 / 16%);
    padding: 20px;
    background: #ffff;
}

.detail-sec-container .detail-content{
    padding: 15px;
}

.detail-sec-container .item-title,
.add-cart-sec-container .item-price,
.about-course{
    color: #2e2e38;
}

.detail-sec-container .sub-title,
.add-cart-sec-container .small-text{
    font-size: 13px;
    color: rgba(0, 0, 0, 0.45);
}

.add-cart-sec .ant-btn {
    color: rgb(228 222 222 / 85%);
    background: #2e2e38c7;
    border-color: #2e2e38;
    width: 100%;
}

.add-cart-sec .ant-btn:hover,
.add-cart-sec .ant-btn:focus,
.add-cart-sec .ant-btn:active {
    background: #2e2e38;
    color: #fff;
}

.module-item-min-height {
    min-height: 76.5vh;
}
