.batch-detail-sec-wrapper,
.batch-sec-wrapper,
.batch-other-details-sec-wrapper{
    border-color: transparent;
    box-shadow: 0 4px 4px -2px rgb(0 0 0 / 16%);
    padding: 20px;
    background: #ffff;
}

.batch-detail-sec-container .batch-title h3::after,
.batch-sec-wrapper .batch-title h3::after {
    position: absolute;
    content: "";
    background: #ffe600;
    height: 4px;
    width: 60px;
    bottom: 2%;
    left: 0%;
}

.batch-detail-sec-container .batch-description p{
    text-align: justify;
}

.card-front__heading {
    margin-top: 1rem;
    color: rgba(255,230,0,1);
}
  
.inside-page__heading { 
    padding-bottom: 1rem; 
    width: 100%;
}
  
.card-front__text-view {
    color: #fafbfa;
    font-size: 1.3rem;
    margin-top: 2rem;
}
 
.card-front__text-price {
    font-size: 1.2rem;
    margin-top: -.2rem;
}
  
.inside-page__text {
    color: #333;
}
  
.card-front__icon {
    fill: #fafbfa;
    font-size: 3vw;
    height: 3.25rem;
    margin-top: -.5rem;
    width: 3.25rem;
}
  
.card-area {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: space-evenly;
    padding: 1rem;
}
  
.card-section {
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
}
  
.card {
    background-color: rgba(0,0,0, .05);
    box-shadow: -.1rem 1.7rem 6.6rem -3.2rem rgba(0,0,0,0.5);
    height: 32rem;
    position: relative;
    transition: all 1s ease;
    width: 30rem;
    z-index: 0;
    display: inline;
    float: left;
    margin-right: 30px;
  }
  
.flip-card {
    height: 32rem;
    perspective: 100rem;
    position: absolute;
    right: 0;
    transition: all 1s ease;
    visibility: hidden;
    width: 30rem;
    z-index: 100;
}
  
.flip-card > * {
    visibility: visible;
}
  
.flip-card__container {
    height: 100%;
    position: absolute;
    right: 0;
    transform-origin: left;
    transform-style: preserve-3d;
    transition: all 1s ease;
    width: 100%;
}
  
.card-front,
.card-back {
    backface-visibility: hidden;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
  
.card-front {
    height: 32rem;
    width: 30rem;
    background-color: #2e2e38;
}
  
.card-front__top {
    color: #fafbfa;
    align-items: center;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 57% 90%, 50% 100%, 43% 90%, 0 90%);
    display: flex;
    flex-direction: column;
    height: 20rem;
    justify-content: center;
    padding: .75rem;
    background: rgba(116,116,128,1);
}

.card-front__bt {
    align-items: center;
    display: flex;
    justify-content: center;
}
  
.card-back {
    background-color: rgba(255,230,0,1);
    transform: rotateX(180deg);
    width: 100%;
    padding: 15px;
    box-shadow: -.1rem 1.7rem 6.6rem -3.2rem rgba(0,0,0,0.5);
}
  
.card-back__container {
    height: auto;
    min-height: 100%;
    object-fit: cover;
    width: 100%;
}
  
  
  
.card.hover-card {
    box-shadow:
    -.1rem 1.7rem 6.6rem -3.2rem rgba(0,0,0,0.75);
    width: 57rem;
}
  
.card.hover-card .flip-card__container {
    transform: rotateX(-180deg);
    width: 57rem;
}


.card-back__container .ant-table {
   color: #2e2e38;
   background: transparent;
}

.card-back__container .ant-table-thead > tr > th {
    color: #2e2e38;
    background: transparent;
    border-bottom: 1px solid #2e2e3866;
    font-weight: 600;
}

.card-back__container .ant-table-thead > tr > th,
.card-back__container .ant-table-tbody > tr > td {
    padding: 10px 10px;
    font-size: 12px;
}

.card-back__container .ant-table-tbody > tr > td {
    border-bottom: 0px solid #2e2e38;
}

.card-back__container .ant-table-tbody > tr.ant-table-row:hover > td {
    background: transparent;
}

.card-front__bt .pin-btn {
    color: #ffe600;
    font-size: 30px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    border: 1px solid #ffe600;
    padding: 8px;
    border-radius: 50%;
}

.card-front__bt .pin-btn:hover {
    cursor: pointer;
    color: #2e2e38;
    background: #dcd3d3;
    border: 1px solid #dcd3d3;
}

.add-batch-cart-btn.ant-btn,
.view-module-btn.ant-btn {
    height: 25px;
    padding: 4px 6px;
    font-size: 10px;
    color: #2e2e38;
    background: transparent;
    border-color: #2e2e38;
}

.add-batch-cart-btn.ant-btn:hover,
.add-batch-cart-btn.ant-btn:focus,
.add-batch-cart-btn.ant-btn:active,
.view-module-btn.ant-btn:hover,
.view-module-btn.ant-btn:focus,
.view-module-btn.ant-btn:active {
    text-decoration: none;
    background: #2e2e38;
    color: #ffff;
}

.combo-class {
    border-style: dashed;
    padding: 8px;
}

.full-combo-btn .ant-btn {
    margin-top: 10px;
    color: rgb(228 222 222 / 85%);
    background: #2e2e38c7;
    border-color: #2e2e38;
    width: 100%;
}

.width-100 .ant-btn {
    width: 100%;
}