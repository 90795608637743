.admin-sec {
	padding: 80px 10px;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}
/* .admin-sec .block {
    font-size: 2rem;
    line-height: 32px;
    text-align: justify;
}

.admin-sec .section-title h3::after {
    position: absolute;
    content: "";
    background: #ffe600;
    height: 4px;
    width: 60px;
    bottom: 4%;
    margin-left: -30px;
    left: 50%;
} */
/* .site-custom-tab-bar {
    z-index: 1;
    background: #fff;
  } */
    /* [data-theme="dark"] .site-custom-tab-bar {
      background: #141414;
    } */