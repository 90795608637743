.description-sec-wrapper,
.other-details-sec-wrapper,
.global-finance-sec-wrapper,
.why-participate-sec-wrapper {
  border-color: transparent;
  box-shadow: 0 4px 4px -2px rgb(0 0 0 / 16%);
  padding: 20px;
  background: #ffff;
}

.sec-title h3::after {
  position: absolute;
  content: "";
  background: #ffe600;
  height: 4px;
  width: 60px;
  bottom: 2%;
  left: 0%;
}
.about-challenge .tagline {
  font-size: 1.4rem;
  color: #98989e;
  line-height: 1.43;
}

p {
  text-align: justify;
}

.scholarship-table .ant-table-thead > tr > th {
  background: #ffe600;
}

.scholarship-table .ant-table-thead > tr > th,
.scholarship-table .ant-table-tbody > tr > td,
.scholarship-table .ant-table tfoot > tr > th,
.scholarship-table .ant-table tfoot > tr > td {
  padding: 10px 17px;
  font-size: 13px;
}

.skill-up-challenge-btn {
  padding: 0 30px;
  margin: 30px 0;
}

.skill-up-challenge-btn .ant-btn {
  color: rgb(228 222 222 / 85%);
  background: #2e2e38c7;
  border-color: #2e2e38;
  width: 100%;
}

.skill-up-challenge-btn .ant-btn:hover,
.skill-up-challenge-btn .ant-btn:focus,
.skill-up-challenge-btn .ant-btn:active {
  background: #2e2e38;
  color: #fff;
}

.hedge-accounting-table .ant-table {
  color: #2e2e38;
  background: transparent;
}

.hedge-accounting-table .ant-table-thead > tr > th {
  background-color: #ffc000;
  color: #2e2e38;
  border-bottom: 1px solid #2e2e3866;
  font-weight: 600;
}

.hedge-accounting-table .ant-table-thead > tr > th,
.hedge-accounting-table .ant-table-tbody > tr > td {
  padding: 10px 10px;
  font-size: 12px;
}

.hedge-accounting-table .ant-table-tbody > tr > td {
  border-bottom: 0px solid #2e2e38;
}

.hedge-accounting-table .ant-table-tbody > tr.ant-table-row:hover > td {
  background: transparent;
}

.hedge-accounting-btn .ant-btn {
  color: rgb(228 222 222 / 85%);
  background: #2e2e38c7;
  border-color: #2e2e38;
  width: 100%;
}

.hedge-accounting-btn .ant-btn:hover,
.hedge-accounting-btn .ant-btn:focus,
.hedge-accounting-btn .ant-btn:active {
  background: #2e2e38;
  color: #fff;
}

.combo-class {
  border-style: dashed;
  padding: 8px;
}

.full-combo-btn .ant-btn {
  margin-top: 10px;
  color: rgb(228 222 222 / 85%);
  background: #2e2e38c7;
  border-color: #2e2e38;
  width: 100%;
}

.width-100 .ant-btn {
  width: 100%;
}
