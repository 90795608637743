/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background: #eee;
} */
.testimonials {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  /* padding: 20px 0; */
}
.testimonials__slider-area {
  display: flex;
  align-items: center;
}
.testimonials__button-container {
  width: 10%;
  display: flex;
}
.testimonials__arrow-btn {
  background: transparent;
  border: 0;
  font-size: 40px;
  border-radius: 35px;
  width: 50px;
  height: 50px;
  display: flex;
  cursor: pointer;
  margin: auto;
}
.testimonials__arrow-btn:hover {
  background: rgba(182, 203, 124, 0.55);
}
.testimonials__arrow-btn:focus {
  outline: 0;
}
.testimonials__arrow-svg {
  margin: auto;
}
.testimonials__slider-container {
  display: flex;
  width: 80%;
  overflow: hidden;
}
.testimonials__slider {
  transition: 1s;
  display: flex;
  width: 100%;
  flex-grow: 1;
}
.testimonials__slide {
  width: 25%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin: calc(25% / 6);
  background: #fefefe;
  color: #484848;
  padding: 25px 0;
  transition: 0.5s;
  box-shadow: 8px 8px 17px 0px rgba(0, 0, 0, 0.55);
  overflow: hidden;
  cursor: pointer;
}
.testimonials__slide:hover {
  transform: scale(1.2);
}
.testimonials__slide--is-active {
  background: #2e2e2e;
  color: #fff;
  transform: scale(1.2);
}
.testimonials__user-avatar {
  margin-left: 20px;
}
.testimonials__avatar {
  transition: 0.5s;
  display: inline-block;
  width: 60px;
  height: 60px;
  background: #555;
  border-radius: 35px;
}
.testimonials__user-info {
  margin-left: 8px;
}
.testimonials__user-info-author {
  margin: 0;
}
.testimonials__user-info-status {
  font-size: 14px;
}
.testimonials__user-info-status--bold {
  font-weight: bold;
}
.testimonials__content-box {
  width: 40%;
  margin: 0 auto;
  background: #2e2e2e;
  color: #fff;
  padding: 25px;
  box-shadow: 8px 8px 17px 0px rgba(0, 0, 0, 0.55);
}
.testimonials__content-box-title {
  text-transform: uppercase;
}
@media screen and (max-width: 960px) {
  .testimonials__slide {
    flex-direction: column;
    text-align: center;
    box-shadow: 8px 8px 10px 0px rgba(0, 0, 0, 0.55);
 }
  .testimonials__user-avatar {
    margin-left: 0;
 }
  .testimonials__user-info {
    margin-left: 0;
 }
}
@media screen and (max-width: auto) {
  .testimonials {
    text-align: center;
 }
  .testimonials .testimonials__slider-area {
    width: 100%;
 }
  .testimonials__slide {
    display: none;
 }
  .testimonials__slide--is-active {
    display: block;
    width: 100%;
 }
  .testimonials__slider {
    transform: translateX(0%) !important;
    width: 100%;
 }
  .testimonials__content-box {
    margin-top: 10px;
    width: 80%;
 }
}
