.about-sec {
	padding: 40px 0;
}
.about-sec .block {
    font-size: 2rem;
    line-height: 32px;
    text-align: justify;
}

.about-sec .section-title h3::after {
    position: absolute;
    content: "";
    background: #ffe600;
    height: 4px;
    width: 60px;
    bottom: 4%;
    margin-left: -30px;
    left: 50%;
}