#contactSection {
	padding: 40px 0;
	background: linear-gradient(to right, #2e2e38 0%, #2e2e38 100%);
	color: rgba(255,255,255,.75);
}
#contactSection .section-title {
	margin-bottom: 40px;
}
#contactSection .section-title p {
	font-size: 16px;
}
#contactSection h3 {
	color: #ffe600;
	margin-top: 10px;
	margin-bottom: 15px;
	padding-bottom: 15px;
}
#contactSection .section-title h3::after {
	position: absolute;
	content: "";
	background: #ffe600;
	height: 4px;
	width: 60px;
	top: 62px;
    left: 0%;
}
#contactSection h4 {
	color: #fff;
	margin-top: 80px;
	margin-bottom: 25px;
	padding-bottom: 20px;
	font-weight: 400;
}
#contactSection form {
	padding-top: 20px;
}
#contactSection .text-danger {
	color: #cc0033;
	text-align: left;
}
#contactSection .btn-custom {
	margin: 30px 0;
	background: transparent;
	border: 2px solid #fff;
	border-radius: 0px;
}
#contactSection .btn-custom:hover {
	color: #1f386e;
	background: #fff;
}
label {
	font-size: 12px;
	font-weight: 400;
	font-family: 'Open Sans', sans-serif;
	float: left;
}
#contactSection .form-control {
	display: block;
	width: 100%;
	padding: 6px 12px;
	font-size: 16px;
	line-height: 1.42857143;
	color: #444;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ddd;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: none;
	-o-transition: none;
	transition: none;
}
#contactSection .form-control:focus {
	border-color: #999;
	outline: 0;
	-webkit-box-shadow: transparent;
	box-shadow: transparent;
}
.form-control::-webkit-input-placeholder {
color: #777;
}
.form-control:-moz-placeholder {
color: #777;
}
.form-control::-moz-placeholder {
color: #777;
}
.form-control:-ms-input-placeholder {
color: #777;
}
#contactSection .contact-item {
	margin: 20px 0;
}
#contactSection .contact-item span {
	color: rgba(255,255,255,1);
	margin-bottom: 10px;
	display: block;
}
#contactSection .contact-item i.fa {
	margin-right: 10px;
}
#contactSection .social {
	border-top: 1px solid rgba(255,255,255,0.15);
	padding-top: 50px;
	margin-top: 50px;
	text-align: center;
}
#contactSection .social ul li {
	display: inline-block;
	margin: 0 20px;
}
#contactSection .social i.fa {
	font-size: 22px;
	width: 48px;
	height: 48px;
	padding: 12px 0;
	border: 2px solid #fff;
	color: #fff;
	border-radius: 50%;
	transition: all 0.3s;
}
#contactSection .social i.fa:hover {
	color: #608dfd;
	background: #fff;
}