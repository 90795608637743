.team-sec {
	padding: 40px 0;
}
.team-sec h4 {
	margin: 5px 0;
}
.team-sec .team-img {
	width: 240px;
}
.team-sec .thumbnail {
	background: transparent;
	border: 0;
}
.team-sec .thumbnail .caption {
	padding: 10px 0 0 0;
	color: #888;
}
.team-sec .section-title h3::after {
	position: absolute;
	content: "";
	background: #ffe600;
	height: 4px;
	width: 60px;
	bottom: 4%;
	margin-left: -30px;
	left: 50%;
}